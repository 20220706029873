const collapsibles = document.querySelectorAll(".collapsible");
collapsibles.forEach((item) =>
  item.addEventListener("click", function () {
    this.classList.toggle("collapsible--expanded");
  })
);


// document.addEventListener('DOMContentLoaded', (event) => {
//   const languageButton = document.getElementById("language-button");
  
//   languageButton.addEventListener("click", () => {
//     const currentUrl = new URL(window.location.href);
  
//     if (currentUrl.pathname.indexOf("_pt") === -1) {
//       // Switch to Portuguese version
//       currentUrl.pathname = currentUrl.pathname.replace(".html", "_pt.html");
//     } else {
//       // Switch to English version
//       currentUrl.pathname = currentUrl.pathname.replace("_pt.html", ".html");
//     }
  
//     window.location.href = currentUrl.href;
//   });
  
// });



